html * {
  font-family: 'public-sans', sans-serif;
}

.error {
  color: darkred;
  margin: 10px;
}

.App {
  text-align: left;

  .container {
    min-width: 800px;
    max-width: 1400px;
    height: 100%;
    margin: 0 auto;
    padding: 0 40px;
    background-color: inherit;
  }

  .banner-inner,
  .navbar-brand {
    padding: 4px 0;
  }

  .section-results {
    min-height: 400px;
  }

  .section-feedback {
    padding: 56px 0;
  }

  .section-upload {
    background-color: #e8ecef;
  }

  .check-icon {
    vertical-align: bottom;
    margin-right: 5px;
  }

  .card-body {
    white-space: normal;
  }
}
